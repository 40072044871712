/* ======================================================================== */
/* 60. SectionContent */
/* ======================================================================== */
class SectionContent extends BaseComponent {
	constructor({
		target,
		scope
	}) {
		super({ target, scope });
	}
}
