/* ======================================================================== */
/* 44. SectionHorizontalScroll */
/* ======================================================================== */
class SectionHorizontalScroll extends BaseComponent {	
	constructor({
		target,
		scope
	}) {
		super({target, scope});
	}

	set() {
		this.scrollingType = this.$target.data('arts-horizontal-scroll-type') || 'modular';
		this.scrollingStartDelay = this.$target.data('arts-horizontal-scroll-start-delay') || 0;
		this.scrollingEndDelay = this.$target.data('arts-horizontal-scroll-end-delay') || 0;
		this.scrollingToggleClass = this.$target.data('arts-horizontal-scroll-toggle-class') || false;
		this.toggleClass = this.scrollingToggleClass.length && this.scrollingToggleClass !== 'false' ? this.scrollingToggleClass : false;
		this.disabledAtBreakpoint = this.$target.data('arts-horizontal-scroll-disabled-at');
		this.triggers = {
			pin: null,
			sections: []
		};
		this.savedSBDamping;
		this.savedOffsetY;
		this.$wrapper = this.$target.find('[data-arts-horizontal-scroll="wrapper"]');
		this.wrapper = this.$wrapper.get(0);
		this.scrollWidth = this.wrapper.scrollWidth; // cache scroll width
		this.offsetWidth = this.target.offsetWidth; // cache offset width
		this.screenViews = this._getScreenViewsAmount();
		this.$sections = this.$target.find('[data-arts-horizontal-scroll="section"]');
	}

	run() {
		this._init();
		this._bindEvents();
	}

	_initLazyImages() {
		const
			$lazyImages = this.$wrapper.find('.lazy:not(.lazy_loaded) img[data-src]'),
			$lazyBackgrounds = this.$wrapper.find('.lazy-bg:not(.lazy_loaded)[data-src]');

		// set lazy loading images in horizontal scrolling section
		if ($lazyImages.length) {
			this.lazyImages = new LazyLoad({
				images: $lazyImages,
				appendScroll: this._getLazyScrollingContainer()
			});

			window.$window.one('arts/barba/transition/start', this.lazyImages.destroy);
		}

		// set lazy loading backgrounds
		if ($lazyBackgrounds.length) {
			this.lazyBackgrounds = new LazyLoad({
				backgrounds: $lazyBackgrounds,
				appendScroll: this._getLazyScrollingContainer()
			});

			window.$window.one('arts/barba/transition/start', this.lazyBackgrounds.destroy);
		}
	}

	_init() {
		if (this._isCurrentlyDisabled()) {
			this._killActiveTriggers();
		} else {
			this._getPinnedWrapper();

			// calculate & run section-by-section translation
			if (this.scrollingType === 'modular' && this.$sections.length) {
				this._getInnerSectionsTranslation();
			}

			setTimeout(() => {
				Animations.refreshAll();
			}, 250);
		}

		this._initLazyImages();
	}

	_saveSmoothScrollbarDamping() {
		if (typeof window.SB !== 'undefined') {
			this.savedSBDamping = window.SB.options.damping;
			window.SB.update();
		}
	}

	_restoreSmoothScrollbarDamping() {
		if (typeof window.SB !== 'undefined') {
			window.SB.options.damping = this.savedSBDamping;
			window.SB.update();
		}
	}

	_saveSmoothScrollbarOffsetY() {
		if (typeof window.SB !== 'undefined') {
			this.savedOffsetY = window.SB.offset.y;
			window.SB.scrollTop = 0;
		} else {
			this.savedOffsetY = window.scrollY;
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'instant'
			});
		}
	}

	_restoreSmoothScrollbarOffsetY() {
		if (typeof window.SB !== 'undefined') {
			window.SB.scrollTop = this.savedOffsetY;
		} else {
			window.scrollTo({
				top: this.savedOffsetY,
				left: 0,
				behavior: 'instant'
			});
		}
	}

	_bindEvents() {
		const self = this;

		window.$window.on(getResponsiveResizeEvent(), debounce(() => {
			self._killActiveTriggers();
			self._saveSmoothScrollbarOffsetY();
			self.set();
			self._init();
			self._restoreSmoothScrollbarOffsetY();
			self._enableScrollMomentum(true);
		}, 250));
	}

	_getLazyScrollingContainer() {
		if (window.Modernizr.touchevents && window.Modernizr.mq(`(${this.disabledAtBreakpoint})`)) {
			return window;
		} else {
			return this.$wrapper;
		}
	}

	_isCurrentlyDisabled() {
		return window.Modernizr.touchevents || window.Modernizr.mq(`(${this.disabledAtBreakpoint})`);
	}

	_killActiveTriggers() {
		if (this.triggers && this.triggers.sections && this.triggers.sections.length) {
			this.triggers.sections.forEach((s) => s.kill(true));
		}

		if (this.triggers && this.triggers.pin) {
			this.triggers.pin.kill(true);
		}

		gsap.set(this.$sections, {
			clearProps: 'all'
		});

		gsap.set(this.wrapper, {
			clearProps: 'all'
		});
	}

	_getPinnedWrapper() {
		let
			animation = new gsap.timeline(),
			delay = 0,
			scrollEvent = new CustomEvent('scroll');

		// In 'modular' scrolling mode the actual scrolling
		// is done with section-by-section translation implemented
		// in _getInnerSectionsTranslation() function
		if (this.scrollingType === 'modular') {
			delay = this.scrollingStartDelay + this.scrollingEndDelay;
		} else {
			delay = this.scrollingStartDelay;
			// In 'wrapper' scrolling we translate the whole container
			animation
				.to(this.wrapper, {
					duration: this.scrollingStartDelay / 2000
				})
				.to(this.wrapper, {
					x: () => `-${this.scrollWidth - this.offsetWidth}`,
					duration: 1,
					ease: 'none'
				})
				.to(this.wrapper, {
					duration: this.scrollingEndDelay / 2000
				});
		}

		this.triggers.pin = ScrollTrigger.create({
			animation,
			trigger: this.$target,
			invalidateOnRefresh: true,
			refreshPriority: 0,
			pin: this.wrapper,
			pinType: typeof window.SB !== 'undefined' ? 'transform' : 'fixed',
			pinSpacing: 'margin',
			anticipatePin: 1,
			scrub: true,
			toggleClass: this.scrollingType === 'modular' ? false : {
				targets: this.wrapper,
				className: this.toggleClass
			},
			start: () => `top top`,
			onUpdate: () => {
				this.wrapper.dispatchEvent(scrollEvent);
			},
			end: () => `top+=${this.scrollWidth - this.offsetWidth + delay} top`,
			onToggle: (instance) => {
				if (instance.isActive) {
					this._enableScrollMomentum(false);
				} else {
					this._enableScrollMomentum(true);
					// Animations.refresh('fixed-reveal');
					Animations.refreshAll();
				}
			},
		});
	}

	_getScreenViewsAmount() {
		return this.scrollWidth / this.offsetWidth;
	}

	_enableScrollMomentum(enable = true) {
		if (enable === true) {
			this._restoreSmoothScrollbarDamping();
		} else {
			if (typeof window.SB !== 'undefined') {
				this._saveSmoothScrollbarDamping();
				window.SB.setMomentum(0, 0);
				window.SB.options.damping = 10;
			}
		}
	}

	_getInnerSectionOffset(el, index) {
		let offset = el.offsetWidth;

		for (let i = 0; i < index; i++) {
			offset += this.$sections.eq(i).get(0).offsetWidth;
		}

		return offset;
	}

	_getInnerSectionsTranslation() {
		const self = this;

		this.$sections.each(function (index) {
			let
				$this = $(this),
				rect = this.getBoundingClientRect(),
				offsetTrigger = 0,
				offsetInner = self._getInnerSectionOffset(this, index),
				outOfView = false,
				trigger = this,
				invalidateOnRefresh = true,
				tl = new gsap.timeline(),
				start,
				end;

			// out of view
			if (rect.left >= self.offsetWidth) {
				outOfView = true;
				offsetTrigger += Math.max(0, rect.left - self.offsetWidth);
			} else {

				// immediately add "in-view" class for the sections
				// which are in view
				if (self.toggleClass) {
					$this.addClass(self.toggleClass);
				}
			}

			// section belongs to the last screen view
			// so correct the offset
			if (rect.right >= self.offsetWidth * (self.screenViews - 1)) {
				offsetInner -= rect.left - self.offsetWidth * (self.screenViews - 1) + rect.width;
			}

			start = outOfView ? `top+=${offsetTrigger + self.scrollingStartDelay} top` : `top+=${self.scrollingStartDelay} top`;
			end = `bottom+=${offsetInner + self.scrollingStartDelay} bottom`;

			tl.fromTo(this, {
				clearProps: 'transform',
				x: () => -offsetTrigger,
				immediateRender: true
			}, {
				duration: 1,
				ease: 'none',
				x: () => -offsetInner,
			});

			const st = ScrollTrigger.create({
				trigger,
				invalidateOnRefresh,
				start,
				end,
				animation: tl,
				scrub: true,
				toggleClass: self.toggleClass,
			});

			self.triggers.sections.push(st);
		});
	}
}
